'use client';

import { getCookie, setCookie } from '../cookiesControls';

const ReffererDetector = () => {
  const cookieReferer = getCookie('referer');
  if (typeof window !== 'undefined' && !cookieReferer) {
    const utmSource = new URLSearchParams(document?.location?.search).get('utm_source');
    const documentReferer = document?.referrer;
    const referer = utmSource || documentReferer;
    !!referer && setCookie('referer', referer, 365);
  }
  return null;
};

export default ReffererDetector;
