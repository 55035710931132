import { ICategory } from '@sapientpro/sapientpro-data-models';

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const SITE_URL = process.env.NEXT_PUBLIC_URL;

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ID;

export const USER_CONSENT_COOKIE_KEY: string = 'user_accepted_cookies';

export const ALL_CATEGORY: ICategory = {
  id: 11111111,
  title: 'All',
  slug: 'all-categories',
};

export const ALL_TOPICS_SLUG = 'all-topics';
