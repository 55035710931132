'use client';

import { useAtom } from 'jotai';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import { GA_TRACKING_ID, USER_CONSENT_COOKIE_KEY } from '../../constants';
import { pageview } from '../gtagHelper';
import isCookiesAccepted from '../store/cookies';

const GoogleAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [cookiesAccepted, setCookiesAccepted] = useAtom(isCookiesAccepted);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isAccepted = localStorage.getItem(USER_CONSENT_COOKIE_KEY) === '1';
      setCookiesAccepted(isAccepted);
    }
  }, []);

  useEffect(() => {
    const url = pathname + searchParams.toString();
    cookiesAccepted && pageview(url);
  }, [pathname, searchParams, GA_TRACKING_ID]);

  return (
    cookiesAccepted ? (
      <>
        <Script
          strategy='afterInteractive'
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
        <Script
          id='gtag-init'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                      });
                    `,
          }}
        />
      </>
      // eslint-disable-next-line react/jsx-no-useless-fragment
    ) : <></>
  );
};

export default GoogleAnalytics;
